import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Poster from '../assets/poster.png';
import { useUser } from '../contexts/UserContext';

const LandingPage = () => {
  const navigate = useNavigate();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const { user, signIn, signOut } = useUser();

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    console.log("Current user state:", user);
  }, [user]);

  const startRoutine = () => {
    navigate('/step1');
  };

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, discard it
      setDeferredPrompt(null);
    } else {
      // Fallback for when the install prompt is not available
      alert("To install the app on your device:\n\n" +
        "1. For iOS: Tap the share button, then 'Add to Home Screen'\n" +
        "2. For Android: Tap the menu button, then 'Add to Home Screen'");
    }
  };

  return (
    <div className="flex flex-col min-h-screen h-screen bg-gray-950">
      <div className="flex-grow flex flex-col items-center justify-center text-white p-4">
        <h1 className="text-4xl font-bold mb-8 text-center">Evolution Brain Activation</h1>
        <div className="w-56 aspect-[9/16] mb-8 bg-gray-800 rounded-lg overflow-hidden shadow-lg relative">
          <video
            className="absolute inset-0 w-full h-full object-cover"
            controls
            poster={Poster}
          >
            <source src='https://ebabybryan.s3.amazonaws.com/EBA-intro_itdrzb.mp4' type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <button
          className="bg-white text-black font-medium py-2 px-6 rounded-md transition duration-300 ease-in-out hover:bg-gray-200 mb-4"
          onClick={startRoutine}
        >
          Start Routine
        </button>
        {deferredPrompt && (
          <button
            className="bg-green-500 text-white font-medium py-2 px-6 rounded-md transition duration-300 ease-in-out hover:bg-green-600 mb-4"
            onClick={handleInstallClick}
          >
            Add to Home Screen
          </button>
        )}
        {user ? (
          <div className="flex flex-col items-center mt-4">
            <p className="text-blue-500 text-sm mb-2">You are signed in</p>
            <button
              className="text-white hover:text-gray-300 transition duration-300 ease-in-out"
              onClick={signOut}
            >
              Sign Out
            </button>
          </div>
        ) : (
          <button
            className="bg-blue-500 text-white font-medium py-2 px-6 rounded-md transition duration-300 ease-in-out hover:bg-blue-600 mt-4"
            onClick={signIn}
          >
            Sign In / Register
          </button>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
