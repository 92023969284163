import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import Footer from './Footer';

const Step3 = () => {
    const navigate = useNavigate();
    const [showVideo, setShowVideo] = useState(false);
    const [currentNostril, setCurrentNostril] = useState('first');

    const toggleVideo = () => setShowVideo(!showVideo);

    const completeNostril = () => {
        if (currentNostril === 'first') {
            setCurrentNostril('second');
        } else {
            setCurrentNostril('done');
        }
    };

    const resetProgress = () => {
        setCurrentNostril('first');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-950">
            <div className="flex-grow flex flex-col items-center text-white p-4">
                <div className="w-full max-w-md pt-10 mb-6">
                    <h1 className="text-3xl font-bold mb-4 text-center">Step 3: Breath through Pressure</h1>
                    <button
                        className="bg-white text-black font-medium py-2 px-6 rounded-md w-full max-w-xs mx-auto block transition duration-300 ease-in-out hover:bg-gray-200"
                        onClick={toggleVideo}
                    >
                        {showVideo ? 'Hide Video' : 'Click to Watch Video 🎥'}
                    </button>
                </div>

                {showVideo && (
                    <div className="w-56 aspect-[9/16] mb-6 bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                        <video
                            className="w-full h-full object-contain"
                            controls
                        >
                            <source src='https://ebabybryan.s3.amazonaws.com/step3.mp4' type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}

                <div className="flex-grow flex flex-col justify-center w-full max-w-md">
                    {currentNostril !== 'done' && (
                        <>
                            <h2 className="text-xl font-semibold mb-2 text-center">{currentNostril === 'first' ? 'First' : 'Second'} Nostril 👃</h2>
                            <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-4">
                                <div className="flex flex-col items-center mb-4">
                                    <div className="text-center">
                                        <p>Breath 4 times on each nostril.</p>
                                        <p>Stop when you feel suffocated.</p>
                                        <p>The goal is 4, but take it one at a time.</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        className="bg-blue-500 text-white font-medium py-2 px-6 rounded-md transition duration-300 ease-in-out hover:bg-blue-600"
                                        onClick={completeNostril}
                                    >
                                        Complete {currentNostril === 'first' ? 'First' : 'Second'} Nostril
                                    </button>
                                </div>
                            </div>
                        </>
                    )}

                    {currentNostril === 'second' && (
                        <div className="text-xl font-semibold mb-2 text-center">First Nostril Done ✅</div>
                    )}

                    {currentNostril === 'done' && (
                        <>
                            <div className="text-xl font-semibold mb-2 text-center">First Nostril Done ✅</div>
                            <div className="text-xl font-semibold mb-2 text-center">Second Nostril Done ✅</div>
                            <button
                                className="bg-green-500 text-white font-medium py-3 px-6 rounded-md mt-6 w-full transition duration-300 ease-in-out hover:bg-green-600"
                                onClick={() => navigate('/step4')}
                            >
                                Go to Step 4 ➡️
                            </button>
                        </>
                    )}

                    <button
                        className="bg-gray-500 text-white font-medium py-2 px-6 rounded-md mt-4 w-full transition duration-300 ease-in-out hover:bg-gray-600"
                        onClick={resetProgress}
                    >
                        Reset Progress
                    </button>
                </div>

                <div className="w-full max-w-md mt-6">
                    <ProgressBar currentStep={3} totalSteps={11} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Step3;
