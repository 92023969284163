import { supabase } from './supabase';

export const markRoutineComplete = async (user) => {
    if (!user) return { error: 'User not authenticated' };

    try {
        // Get the current date in the user's local timezone
        const today = new Date().toLocaleDateString('en-CA'); // Format: YYYY-MM-DD

        // Check if a completion already exists for today
        const { data: existingCompletion, error: fetchError } = await supabase
            .from('routine_completions')
            .select('id')
            .eq('user_id', user.id)
            .eq('completion_date', today)
            .single();

        if (fetchError && fetchError.code !== 'PGRST116') { // PGRST116 means no rows returned
            console.error('Error checking existing completion:', fetchError);
            return { error: 'Error checking completion status' };
        }

        if (existingCompletion) {
            return { alreadyCompleted: true };
        }

        // If no completion exists, insert a new one
        const { error: insertError } = await supabase
            .from('routine_completions')
            .insert({ user_id: user.id, completion_date: today });

        if (insertError) {
            console.error('Error inserting completion:', insertError);
            return { error: 'Error saving completion' };
        }

        return { success: true };
    } catch (error) {
        console.error('Unexpected error in markRoutineComplete:', error);
        return { error: 'Unexpected error occurred' };
    }
};

export const getRoutineCompletions = async (user) => {
    if (!user) return [];

    const { data, error } = await supabase
        .from('routine_completions')
        .select('completion_date')
        .eq('user_id', user.id)
        .order('completion_date', { ascending: false });

    if (error) {
        console.error('Error fetching completions:', error);
        throw error;
    }

    return data ? data.map(item => item.completion_date) : [];
};