import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';

const AuthCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleAuthCallback = async () => {
            console.log("Auth callback initiated");

            const { data, error } = await supabase.auth.getSession();

            if (error) {
                console.error('Error getting session:', error);
            } else if (data?.session) {
                console.log('Session retrieved successfully:', data.session);
            } else {
                console.log('No active session found');
            }

            // Redirect to home page regardless of the outcome
            navigate('/');
        };

        handleAuthCallback();
    }, [navigate]);

    return <div>Processing authentication...</div>;
};

export default AuthCallback;
