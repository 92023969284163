import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import Footer from './Footer';

const Step2 = () => {
    const navigate = useNavigate();
    const [showVideo, setShowVideo] = useState(false);
    const [currentSide, setCurrentSide] = useState('left');

    const toggleVideo = () => setShowVideo(!showVideo);

    const completeSide = () => {
        if (currentSide === 'left') {
            setCurrentSide('right');
        } else {
            setCurrentSide('done');
        }
    };

    const resetProgress = () => {
        setCurrentSide('left');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-950">
            <div className="flex-grow flex flex-col items-center text-white p-4">
                <div className="w-full max-w-md pt-10 mb-6">
                    <h1 className="text-3xl font-bold mb-4 text-center">Step 2: Same Side Nostril Breathing</h1>
                    <button
                        className="bg-white text-black font-medium py-2 px-6 rounded-md w-full max-w-xs mx-auto block transition duration-300 ease-in-out hover:bg-gray-200"
                        onClick={toggleVideo}
                    >
                        {showVideo ? 'Hide Video' : 'Click to Watch Video 🎥'}
                    </button>
                </div>

                {showVideo && (
                    <div className="w-56 aspect-[9/16] mb-6 bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                        <video
                            className="w-full h-full object-contain"
                            controls
                        >
                            <source src='https://ebabybryan.s3.amazonaws.com/step2.mp4' type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}

                <div className="flex-grow flex flex-col justify-center w-full max-w-md">
                    {currentSide !== 'done' && (
                        <>
                            <h2 className="text-xl font-semibold mb-2 text-center">{currentSide.charAt(0).toUpperCase() + currentSide.slice(1)} Side 👃</h2>
                            <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-4">
                                <div className="flex flex-col items-center mb-4">
                                    <div className="text-left">
                                        <p>4 breaths</p>
                                        <p>Hum and swallow</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        className="bg-blue-500 text-white font-medium py-2 px-6 rounded-md transition duration-300 ease-in-out hover:bg-blue-600"
                                        onClick={completeSide}
                                    >
                                        Complete {currentSide.charAt(0).toUpperCase() + currentSide.slice(1)} Side
                                    </button>
                                </div>
                            </div>
                        </>
                    )}

                    {currentSide === 'right' && (
                        <div className="text-xl font-semibold mb-2 text-center">Left Side Done ✅</div>
                    )}

                    {currentSide === 'done' && (
                        <>
                            <div className="text-xl font-semibold mb-2 text-center">Left Side Done ✅</div>
                            <div className="text-xl font-semibold mb-2 text-center">Right Side Done ✅</div>
                            <button
                                className="bg-green-500 text-white font-medium py-3 px-6 rounded-md mt-6 w-full transition duration-300 ease-in-out hover:bg-green-600"
                                onClick={() => navigate('/step3')}
                            >
                                Go to Step 3 ➡️
                            </button>
                        </>
                    )}

                    <button
                        className="bg-gray-500 text-white font-medium py-2 px-6 rounded-md mt-4 w-full transition duration-300 ease-in-out hover:bg-gray-600"
                        onClick={resetProgress}
                    >
                        Reset Progress
                    </button>
                </div>

                <div className="w-full max-w-md mt-6">
                    <ProgressBar currentStep={2} totalSteps={11} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Step2;
