import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import Footer from './Footer';
import { useUser } from '../contexts/UserContext';
import { markRoutineComplete } from '../utils/routineTracker';
import HabitTracker from './HabitTracker';

const CompletionScreen = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [completionStatus, setCompletionStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    const completeRoutine = useCallback(async () => {
        if (user) {
            try {
                const result = await markRoutineComplete(user);
                if (result.alreadyCompleted) {
                    setCompletionStatus('alreadyCompleted');
                } else if (result.success) {
                    setCompletionStatus('completed');
                } else if (result.error) {
                    setCompletionStatus('error');
                    setErrorMessage(result.error);
                } else {
                    // Handle unexpected result
                    setCompletionStatus('error');
                    setErrorMessage('Unexpected response from server');
                }
            } catch (error) {
                console.error('Error in completeRoutine:', error);
                setCompletionStatus('error');
                setErrorMessage('An unexpected error occurred');
            }
        } else {
            setCompletionStatus('notLoggedIn');
        }
    }, [user]);

    useEffect(() => {
        completeRoutine();
    }, [completeRoutine]);

    const renderCompletionMessage = () => {
        switch (completionStatus) {
            case 'completed':
                return "Evolution Brain Activation Completed!";
            case 'alreadyCompleted':
                return "You've already completed the routine today. Great job!";
            case 'error':
                return "There was an error recording your completion.";
            case 'notLoggedIn':
                return "Sign in to track your progress!";
            default:
                return "Completing your routine...";
        }
    };

    const handleRetry = () => {
        setCompletionStatus('loading');
        setErrorMessage('');
        completeRoutine();
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-950">
            {completionStatus === 'completed' && (
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    recycle={false}
                    numberOfPieces={200}
                />
            )}
            <div className="flex-grow flex flex-col gap-4 items-center justify-center text-white p-4">
                <div className="w-full max-w-md bg-gray-800 rounded-lg overflow-hidden shadow-xl">
                    <div className="p-6">
                        <HabitTracker />
                    </div>
                </div>
                <div className="w-full max-w-md bg-gray-800 rounded-lg overflow-hidden shadow-xl mb-8">
                    <div className="p-6">
                        <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-center">{renderCompletionMessage()}</h1>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm sm:text-lg font-semibold">ORIGINAL</span>
                            <span className="text-xl sm:text-xl font-bold">13 Steps</span>
                        </div>
                        <div className="flex justify-between items-center mb-6 bg-gray-700 p-4 rounded-lg">
                            <span className="text-sm sm:text-lg font-semibold">COMPLETED</span>
                            <div>
                                <span className="text-xl sm:text-xl font-bold text-blue-400">13 Steps</span>
                                <span className="ml-2 text-xs sm:text-sm bg-blue-400 text-white px-2 py-1 rounded">100% Done</span>
                            </div>
                        </div>
                        <p className="text-lg sm:text-xl mb-4 text-center">
                            Now you are ready to go and have an amazing day because you are prepared for it.
                        </p>
                        <p className="text-lg sm:text-xl mb-4 text-center">
                            See you again tomorrow.
                        </p>
                        <p className="text-xl sm:text-2xl font-semibold mb-6 text-center text-blue-400">
                            Have the day you deserve!
                        </p>
                        {completionStatus === 'error' && (
                            <div className="text-red-500 text-sm mt-2">
                                <p>{errorMessage}</p>
                                <button
                                    className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                                    onClick={handleRetry}
                                >
                                    Retry
                                </button>
                            </div>
                        )}
                        <button
                            className="w-full bg-gray-500 hover:bg-gray-600 text-white font-bold py-3 px-6 rounded-md transition duration-300 ease-in-out"
                            onClick={() => navigate('/')}
                        >
                            Back to Home
                        </button>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    );
};

export default CompletionScreen;
