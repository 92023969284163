import React, { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';
import { getRoutineCompletions } from '../utils/routineTracker';

const HabitTracker = () => {
    const { user } = useUser();
    const [completions, setCompletions] = useState([]);

    useEffect(() => {
        const fetchCompletions = async () => {
            if (user) {
                const data = await getRoutineCompletions(user);
                setCompletions(data);
            }
        };

        fetchCompletions();
    }, [user]);

    if (!user) {
        return (
            <div className="mt-6 text-center">
                <p>Want to keep track of your progress?</p>
                <button
                    className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {/* Implement sign-in logic here */ }}
                >
                    Sign in to track your progress
                </button>
            </div>
        );
    }

    // Helper function to generate the last 120 days
    const getLast120Days = () => {
        const days = [];
        for (let i = 119; i >= 0; i--) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            days.push(date.toISOString().split('T')[0]);
        }
        return days;
    };

    const last120Days = getLast120Days();
    const completionSet = new Set(completions);

    return (
        <div className="w-full">
            <h2 className="text-xl font-bold mb-2">Your EBA Progress</h2>
            <p className="text-sm mb-4">Data shows the last 120 days</p>
            <div className="flex flex-wrap justify-start">
                {last120Days.map((date) => (
                    <div
                        key={date}
                        className={`w-[calc(100%/20-4px)] aspect-square m-[2px] rounded-sm ${completionSet.has(date) ? 'bg-blue-500' : 'bg-white border border-gray-300'
                            }`}
                        title={date}
                    ></div>
                ))}
            </div>
            <div className="mt-4 text-sm flex items-center">
                <div className="w-4 h-4 bg-blue-500 rounded-sm mr-2"></div>
                <span className="mr-4">Completed</span>
                <div className="w-4 h-4 bg-white border border-gray-300 rounded-sm mr-2"></div>
                <span>Not completed</span>
            </div>
            <p className="mt-4 text-sm">
                Your current streak: {completions.filter(date => last120Days.includes(date)).length} days
            </p>
        </div>
    );
};

export default HabitTracker;