import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import Footer from './Footer';

const Step5 = () => {
    const navigate = useNavigate();
    const [showVideo, setShowVideo] = useState(false);
    const [checks, setChecks] = useState({
        firstSide: false,
        secondSide: false,
        both: false,
        lookFirstSide: false,
        lookSecondSide: false
    });

    const toggleVideo = () => setShowVideo(!showVideo);

    const handleCheck = (key) => {
        setChecks(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const allChecked = Object.values(checks).every(Boolean);

    return (
        <div className="flex flex-col min-h-screen bg-gray-950">
            <div className="flex-grow flex flex-col items-center text-white p-4">
                <div className="w-full max-w-md pt-10 mb-6">
                    <h1 className="text-3xl font-bold mb-4 text-center">Step 5: Unlock Shock</h1>
                    <button
                        className="bg-white text-black font-medium py-2 px-6 rounded-md w-full max-w-xs mx-auto block transition duration-300 ease-in-out hover:bg-gray-200"
                        onClick={toggleVideo}
                    >
                        {showVideo ? 'Hide Video' : 'Click to Watch Video 🎥'}
                    </button>
                </div>

                {showVideo && (
                    <div className="w-56 aspect-[9/16] mb-6 bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                        <video
                            className="w-full h-full object-contain"
                            controls
                        >
                            <source src='https://ebabybryan.s3.amazonaws.com/step5.mp4' type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}

                <div className="w-full max-w-md flex-grow flex flex-col justify-center">
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-4">
                        <h2 className="text-xl font-semibold mb-4 text-center">Check off as you complete:</h2>
                        <div className="space-y-4">
                            {[
                                ['firstSide', 'Shoulder Shrug First Side'],
                                ['secondSide', 'Shoulder Shrug Second Side'],
                                ['both', 'Shoulder Shrug Both'],
                                ['lookFirstSide', 'Shoulder Shrug Look First Side'],
                                ['lookSecondSide', 'Shoulder Shrug Look Second Side']
                            ].map(([key, label]) => (
                                <label key={key} className="flex items-center space-x-3">
                                    <input
                                        type="checkbox"
                                        checked={checks[key]}
                                        onChange={() => handleCheck(key)}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="text-gray-300 font-medium">{label}</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    {allChecked && (
                        <button
                            className="bg-green-500 text-white font-medium py-3 px-6 rounded-md mt-6 w-full transition duration-300 ease-in-out hover:bg-green-600"
                            onClick={() => navigate('/step6')}
                        >
                            Go to Step 6 ➡️
                        </button>
                    )}

                    <button
                        className="bg-gray-500 text-white font-medium py-2 px-6 rounded-md mt-4 w-full transition duration-300 ease-in-out hover:bg-gray-600"
                        onClick={() => setChecks({
                            firstSide: false,
                            secondSide: false,
                            both: false,
                            lookFirstSide: false,
                            lookSecondSide: false
                        })}
                    >
                        Reset Progress
                    </button>
                </div>

                <div className="w-full max-w-md mt-6">
                    <ProgressBar currentStep={5} totalSteps={13} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Step5;
