import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from '../utils/supabase';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
            (event, session) => {
                console.log("Auth state changed:", event, session);
                setUser(session?.user ?? null);
                setLoading(false);
            }
        );

        // Initial session check
        supabase.auth.getSession().then(({ data: { session } }) => {
            console.log("Initial session check:", session);
            setUser(session?.user ?? null);
            setLoading(false);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const signIn = async () => {
        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: `${window.location.origin}/auth-callback`
                }
            });

            if (error) {
                console.error('Error initiating sign in:', error);
                return { error };
            }

            // The user will be redirected to the OAuth provider here
            return { error: null };
        } catch (error) {
            console.error('Unexpected error during sign in:', error);
            return { error };
        }
    };

    const signOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) console.error('Error signing out:', error);
        setUser(null);
    };

    const value = {
        user,
        loading,
        signIn,
        signOut,
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);