import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import Footer from './Footer';

const Step1 = () => {
    const navigate = useNavigate();
    const [showVideo, setShowVideo] = useState(false);
    const [currentNostril, setCurrentNostril] = useState('left');

    const toggleVideo = () => setShowVideo(!showVideo);

    const completeNostril = () => {
        if (currentNostril === 'left') {
            setCurrentNostril('right');
        } else {
            setCurrentNostril('done');
        }
    };

    const resetProgress = () => {
        setCurrentNostril('left');
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-950">
            <div className="flex-grow flex flex-col items-center text-white p-4">
                <div className="w-full max-w-md pt-10 mb-6">
                    <h1 className="text-3xl font-bold mb-4 text-center">Step 1: Olbas Inhale</h1>
                    <button
                        className="bg-white text-black font-medium py-2 px-6 rounded-md w-full max-w-xs mx-auto block transition duration-300 ease-in-out hover:bg-gray-200"
                        onClick={toggleVideo}
                    >
                        {showVideo ? 'Hide Video' : 'Click to Watch Video 🎥'}
                    </button>
                </div>

                {showVideo && (
                    <div className="w-full max-w-md aspect-w-9 aspect-h-16 mb-6">
                        <video
                            className="w-full h-full object-contain"
                            controls
                        >
                            <source src='https://ebabybryan.s3.amazonaws.com/step1.mp4' type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}

                <div className="w-full max-w-md flex-grow flex flex-col justify-center">
                    {currentNostril !== 'done' && (
                        <>
                            <h2 className="text-xl font-semibold mb-2 text-center">{currentNostril.charAt(0).toUpperCase() + currentNostril.slice(1)} Nostril 👃</h2>
                            <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-4">
                                <div className="flex flex-col items-center mb-4">
                                    <div className="text-left">
                                        <p>Take 4 silent inhales</p>
                                        <p>Take 4 silent exhales</p>
                                        <p>After the 4th exhale, breathe in, hum and swallow</p>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        className="bg-blue-500 text-white font-medium py-2 px-6 rounded-md transition duration-300 ease-in-out hover:bg-blue-600"
                                        onClick={completeNostril}
                                    >
                                        Complete {currentNostril.charAt(0).toUpperCase() + currentNostril.slice(1)} Nostril
                                    </button>
                                </div>
                            </div>
                        </>
                    )}

                    {currentNostril === 'right' && (
                        <div className="text-xl font-semibold mb-2 text-center">Left Nostril Done ✅</div>
                    )}

                    {currentNostril === 'done' && (
                        <>
                            <div className="text-xl font-semibold mb-2 text-center">Left Nostril Done ✅</div>
                            <div className="text-xl font-semibold mb-2 text-center">Right Nostril Done ✅</div>
                            <button
                                className="bg-green-500 text-white font-medium py-3 px-6 rounded-md mt-6 w-full transition duration-300 ease-in-out hover:bg-green-600"
                                onClick={() => navigate('/step2')}
                            >
                                Go to Step 2 ➡️
                            </button>
                        </>
                    )}

                    <button
                        className="bg-gray-500 text-white font-medium py-2 px-6 rounded-md mt-4 w-full transition duration-300 ease-in-out hover:bg-gray-600"
                        onClick={resetProgress}
                    >
                        Reset Progress
                    </button>
                </div>

                <div className="w-full max-w-md mt-6">
                    <ProgressBar currentStep={1} totalSteps={13} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Step1;
