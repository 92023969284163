import React from 'react';

const Footer = () => {
    return (
        <div className="w-full bg-gray-950 text-gray-400 py-4 mb-4 px-4 text-center text-sm">
            <div className="bg-gray-800 rounded-lg p-5 inline-block">
                <p>
                    Created with <span className="text-green-500">💚</span> by{' '}
                    <a
                        href="https://quantumfitness.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white underline"
                    >
                        Bryan Mirabella
                    </a>
                    {' '}and Quantum Fitness.
                    In collaboration with{' '}
                    <a
                        href="https://resiliencia.studio/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white underline"
                    >
                        Resiliencia Studio
                    </a>
                </p>
            </div>
        </div>
    );
};

export default Footer;
