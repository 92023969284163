import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import Footer from './Footer';

const Step10 = () => {
    const navigate = useNavigate();
    const [showVideo, setShowVideo] = useState(false);
    const [stretches, setStretches] = useState({
        up: false,
        down: false,
        left: false,
        right: false
    });

    const toggleVideo = () => setShowVideo(!showVideo);

    const handleStretchCheck = (direction) => {
        setStretches(prev => ({ ...prev, [direction]: !prev[direction] }));
    };

    const allStretchesCompleted = Object.values(stretches).every(Boolean);

    return (
        <div className="flex flex-col min-h-screen bg-gray-950">
            <div className="flex-grow flex flex-col items-center text-white p-4">
                <div className="w-full max-w-md mt-10 mb-6">
                    <h1 className="text-3xl font-bold mb-4 text-center">Step 10: Hyoid Release</h1>
                    <button
                        className="bg-white text-black font-medium py-2 px-6 rounded-md w-full max-w-xs mx-auto block transition duration-300 ease-in-out hover:bg-gray-200"
                        onClick={toggleVideo}
                    >
                        {showVideo ? 'Hide Video' : 'Click to Watch Video 🎥'}
                    </button>
                </div>

                {showVideo && (
                    <div className="w-56 aspect-[9/16] mb-6 bg-gray-800 rounded-lg overflow-hidden shadow-lg">
                        <video
                            className="w-full h-full object-contain"
                            controls
                        >
                            <source src='https://ebabybryan.s3.amazonaws.com/step10.mp4' type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}

                <div className="flex-grow flex flex-col justify-center w-full max-w-md">
                    <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-4">
                        <h2 className="text-xl font-semibold mb-4 text-center">Check off each stretch as you complete it:</h2>
                        <div className="space-y-4">
                            {[
                                ['up', 'Stretch Up'],
                                ['down', 'Stretch Down'],
                                ['left', 'Stretch Left'],
                                ['right', 'Stretch Right']
                            ].map(([direction, label]) => (
                                <label key={direction} className="flex items-center space-x-3">
                                    <input
                                        type="checkbox"
                                        checked={stretches[direction]}
                                        onChange={() => handleStretchCheck(direction)}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="text-gray-300 font-medium">{label}</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    {allStretchesCompleted && (
                        <button
                            className="bg-green-500 text-white font-medium py-3 px-6 rounded-md mt-6 w-full transition duration-300 ease-in-out hover:bg-green-600"
                            onClick={() => navigate('/step11')}
                        >
                            Go to Step 11 ➡️
                        </button>
                    )}

                    <button
                        className="bg-gray-500 text-white font-medium py-2 px-6 rounded-md mt-4 w-full transition duration-300 ease-in-out hover:bg-gray-600"
                        onClick={() => setStretches({ up: false, down: false, left: false, right: false })}
                    >
                        Reset Progress
                    </button>
                </div>

                <div className="w-full max-w-md mt-6">
                    <ProgressBar currentStep={10} totalSteps={13} />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Step10;
