import React from 'react';

const ProgressBar = ({ currentStep, totalSteps }) => {
    const progress = (currentStep / totalSteps) * 100;

    return (
        <div className="w-full max-w-md mb-8">
            <div className="bg-gray-700 h-2 rounded-full">
                <div
                    className="bg-blue-500 h-2 rounded-full transition-all duration-300 ease-in-out"
                    style={{ width: `${progress}%` }}
                ></div>
            </div>
            <div className="text-center mt-2 text-sm text-gray-400">
                Step {currentStep} of {totalSteps}
            </div>
        </div>
    );
};

export default ProgressBar;
